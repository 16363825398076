<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <select-funcionario v-if="!view" class="mr-2"></select-funcionario>
          <v-text-field
            v-else
            class="mr-2"
            label="Funcionário"
            name="funcionario"
            placeholder="Funcionário"
            v-model="funcionario"
            :readonly="true"
          ></v-text-field>
          <select-praca v-if="!view" :cod-funcionario="idFuncionario" class="mr-2"></select-praca>
          <v-text-field
            v-else
            class="mr-2"
            label="Praça"
            name="praca"
            placeholder="Praça"
            v-model="praca"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-menu
            v-if="!view"
            class="pr-2"
            ref="bornDate"
            lazy
            :close-on-content-click="false"
            v-model="dataSaida"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataSaidaRomaneio"
          >
            <v-text-field
              slot="activator"
              label="Data saída"
              v-model="dataSaidaRomaneio"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataSaidaRomaneio" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataSaida = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.bornDate.save(dataSaidaRomaneio)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-else
            class="mr-2"
            label="Data saída"
            name="dataSaida"
            placeholder="Data saída"
            v-model="dataSaidaRomaneio"
            :readonly="true"
          ></v-text-field>
          <v-menu
            v-if="!view"
            class="pr-2"
            ref="retornoDate"
            lazy
            :close-on-content-click="false"
            v-model="dataRetorno"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataRetornoRomaneio"
          >
            <v-text-field
              slot="activator"
              label="Data retorno"
              v-model="dataRetornoRomaneio"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataRetornoRomaneio" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dataRetorno = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.retornoDate.save(dataRetornoRomaneio)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-else
            class="mr-2"
            label="Data retorno"
            name="dataRetorno"
            placeholder="Data retorno"
            v-model="dataRetornoRomaneio"
            :readonly="true"
          ></v-text-field>
          <v-switch v-model="switch1" :label="`Status: ${switch1 == true ? 'FINALIZADO' : 'INICIADO'}`"></v-switch>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <select-produto class="mr-2"></select-produto>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td style="display:none;">{{ props.item.idProduto }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.precoVenda }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.quantidadeDisponivel }}</td>
              <td>
                <v-text-field
                  style="width:80px"
                  type="number"
                  name="quantidade"
                  v-model="props.item.quantidade"
                  required
                  :readonly="props.item.id != 0"
                ></v-text-field>
              </td>
              <td style="width:250px">
                <div class="d-flex">
                  <v-divider class="mx-3" inset vertical></v-divider>
                  <v-text-field
                    style="width:80px"
                    type="number"
                    label="Nº sacola"
                    name="sacola"
                    v-model="props.item.idSacola"
                    required
                    :readonly="props.item.id != 0"
                  ></v-text-field>
                  <v-btn
                    title="Visualizar códigos vinculados à sacola"
                    icon
                    color="primary"
                    @click.stop="visualizarSacola(props.item)"
                    v-if="props.item.id == 0"
                  >
                    <v-icon>notes</v-icon>
                  </v-btn>
                  <v-divider class="mx-3" inset vertical></v-divider>
                  <v-btn
                    title="Adicionar código de barras"
                    icon
                    color="primary"
                    @click.stop="abrirModal(props.item)"
                    v-if="props.item.id == 0"
                  >
                    <v-icon>post_add</v-icon>
                  </v-btn>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
        <v-dialog v-model="dialog" max-width="800">
          <v-card v-if="errosValidacoes">
            <v-card-title class="headline">Falha ao salvar romaneio</v-card-title>
            <v-card-text>
              {{ mensagemErro }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark text @click="dialog = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="produtoModal">
            <v-card-title class="headline">Código de barras</v-card-title>
            <v-card-text>
              <v-textarea
                solo
                name="codigoBarras"
                label="Código de barras"
                rows="15"
                v-model="produtoModal.codigoBarras"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark text @click="dialog = false">Cancelar</v-btn>
              <v-btn color="primary" dark text @click="validarCodigoBarra(produtoModal)">Salvar</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="codigosNaSacola">
            <v-card-title class="headline">Códigos de barras</v-card-title>
            <v-card-text>
              <v-data-table :headers="headersSacola" :items="codigosNaSacola" class="elevation-0">
                <template v-slot:items="props">
                  <td>{{ props.item }}</td>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark text @click="dialog = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="form-btn">
          <v-btn @click="voltar" outline>Voltar</v-btn>
          <v-btn outline @click="submit" color="primary">Salvar</v-btn>
        </div>
        <div>
          <progress-circular></progress-circular>
        </div>
        <v-snackbar :color="'error'" v-model="snackbar" :top="true">
          {{ text }}
          <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoRomaneio } from "../../servicos/servicoRomaneio"
import { ServicoCodigoBarras } from "../../servicos/servicoCodigoBarras"
import ProgressCircular from "../progressCircular/progressCircular"
import ProdutosRomaneio from "../../domain/romaneio/ProdutosRomaneio"
import Romaneio from "../../domain/romaneio/Romaneio"
import SelectFuncionario from "../funcionario/selectFuncionario.vue"
import SelectPraca from "../praca/selectPracaPorFuncionario.vue"
import SelectProduto from "../produto/select/selectProdutos.vue"
import ServicoSacola from "../../servicos/servicoSacola"
const servicoSacola = new ServicoSacola()
const servicoRomaneio = new ServicoRomaneio()
const servicoCodigoBarras = new ServicoCodigoBarras()
export default {
  components: {
    SelectFuncionario,
    SelectPraca,
    SelectProduto,
    ProgressCircular
  },
  data() {
    return {
      dialog: false,
      id: null,
      valid: false,
      idFuncionario: null,
      funcionario: "",
      praca: "",
      idPraca: null,
      dataSaida: false,
      dataSaidaRomaneio: null,
      dataRetorno: null,
      dataRetornoRomaneio: null,
      produtos: [],
      codigosNaSacola: null,
      produtoModal: null,
      errosValidacoes: false,
      mensagemErro: null,
      switch1: true,
      view: false,
      snackbar: false,
      text: "",
      headers: [
        { text: "Nome", align: "left", sortable: false, value: "referenciaInformada" },
        { text: "Preço", align: "left", sortable: false, value: "dataGeracao" },
        { text: "Referência", align: "left", sortable: false, value: "quantidade" },
        { text: "Qtd. disponível", value: "action", align: "left", sortable: false },
        { text: "Quantidade", align: "left", sortable: false }
      ],
      headersSacola: [{ text: "Código de barras", align: "left", sortable: false, value: "item" }]
    }
  },
  created() {
    serverBus.$on("funcionarioSelected", value => {
      this.idFuncionario = value
    })
    serverBus.$on("pracaSelected", value => {
      this.idPraca = value
    })
    serverBus.$on("produtosSelecionados", value => {
      this.produtos = value
    })
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoRomaneio.buscarPorId(this.id).then(res => {
        this.view = true
        this.id = res.data.id
        this.idFuncionario = res.data.idFuncionario
        this.funcionario = res.data.funcionario
        this.praca = res.data.praca
        this.idPraca = res.data.idPraca
        this.dataSaidaRomaneio = res.data.dataSaidaString
        this.dataRetornoRomaneio = res.data.dataRetornoString
        this.produtos = res.data.produtos
        this.switch1 = res.data.finalizado

        serverBus.$emit("produtoSelected", res.data.produtos)
      })
    }
  },
  methods: {
    submit() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate()) {
        let romaneio = new Romaneio()
        romaneio.Id = this.id != undefined ? parseInt(this.id) : 0
        romaneio.IdFuncionario = parseInt(this.idFuncionario)
        romaneio.IdPraca = parseInt(this.idPraca)
        romaneio.DataSaida = this.validarData(this.dataSaidaRomaneio)
        romaneio.DataRetorno = this.validarData(this.dataRetornoRomaneio)
        romaneio.Finalizado = this.switch1
        romaneio.Produtos = []
        this.produtos.forEach(element => {
          var produto = new ProdutosRomaneio()
          if (element.codigoBarras != null) {
            var codigosBarra = element.codigoBarras.split("\n")
            produto.codigoBarras = codigosBarra
          }
          produto.id = element.id != undefined ? parseInt(element.id) : 0
          produto.idRomaneio = this.id != undefined ? parseInt(this.id) : 0
          produto.codProduto = parseInt(element.idProduto)
          produto.quantidade = parseInt(element.quantidade)
          produto.idSacola = element.idSacola != null ? parseInt(element.idSacola) : null
          romaneio.Produtos.push(produto)
        })
        servicoRomaneio.salvar(romaneio).then(
          res => {
            if (res.status === 200 && res.data.length === undefined) {
              this.fecharProgressCircular()
              this.$router.push({ name: "romaneio" })
            } else {
              this.fecharProgressCircular()
              this.dialog = true
              this.codigosNaSacola = null
              this.errosValidacoes = true
              this.mensagemErro = res.data[0].title + " - " + res.data[0].detail
            }
          },
          err => {
            this.fecharProgressCircular()
            // eslint-disable-next-line
            console.log(err)
          }
        )
      } else {
        this.fecharProgressCircular()
      }
    },
    validarData(data) {
      var dataComBarra = data.split("/")
      if (dataComBarra.length > 1) {
        return new Date(dataComBarra[2], dataComBarra[1], dataComBarra[0])
      } else {
        return new Date(data)
      }
    },
    validarCodigoBarra(produtoRomaneio) {
      if (produtoRomaneio.codigosBarra == null) {
        this.dialog = false
      }
      this.abrirProgressCircular()
      let codigosBarra = produtoRomaneio.codigoBarras.split("\n")
      if (produtoRomaneio.quantidade == codigosBarra.length) {
        servicoCodigoBarras.getCodigosInvalidos(codigosBarra, produtoRomaneio.idProduto).then(res => {
          if (res.data.length > 0) {
            this.fecharProgressCircular()
            alert(res.data)
          } else {
            this.fecharProgressCircular()
            this.dialog = false
          }
        })
      } else {
        this.fecharProgressCircular()
        alert(
          "Quantidade informada " +
            produtoRomaneio.quantidade +
            " foram informados " +
            codigosBarra.length +
            " códigos de barras"
        )
        this.dialog = true
      }
    },
    abrirModal(produto) {
      this.produtoModal = produto
      this.codigosNaSacola = null
      this.dialog = true
    },
    visualizarSacola(produto) {
      if (produto != null && produto.idSacola != null && produto.idSacola != "") {
        this.produtoModal = null

        servicoSacola.getSacolaById(produto.idSacola).then(res => {
          if (res.status === 200) {
            if (res.data == null) {
              this.snackbar = true
              this.text = "Sacola não encontrada. Verifique."
            } else if (res.data.length > 0 && res.data[0].status == 412) {
              this.snackbar = true
              this.text = res.data[0].detail
            } else {
              produto.quantidade = res.data.produtos.length
              this.codigosNaSacola = res.data.produtos
            }
          }
        })

        this.dialog = true
      }
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "romaneio" })
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
